<template>
  <div class="custom-wizard">
    <div class="wizard-box" :class="[{'is-mobile': isMobile}]">
      <div class="wizard-steps">
        <template v-for="(option, key) in getAvailableSteps">
          <div
              :key="key"
              class="wizard-step-item"
              :class="[{'active': key === activeIndex, 'passed': key < activeIndex}]"
          >
            <div class="wizard-step-count">
              <custom-icon
                  v-if="key < activeIndex"
                  icon="CHECK"
                  height="20px"
                  width="20px"
                  color-code="#fff"
              />
              <template v-else>
                {{ key + 1 }}
              </template>
            </div>
            <div class="wizard-step-text">{{ option.text }}</div>
            <div v-if="key === activeIndex" class="wizard-step-icon">
              <custom-icon
                  :icon="$vs.rtl ? 'CHEVRON_LEFT' : 'CHEVRON_RIGHT'"
                  height="15px"
                  width="15px"
                  color-code="#cdcdcd"
              />
            </div>
          </div>
        </template>
      </div>
      <div class="wizard-content">
        <template v-if="getAvailableSteps.length > 0">
          <slot />
        </template>

        <div class="wizard-actions">
          <div class="wizard-action-button previous" @click="handleClickPreviousItem">
            {{ $t('wizard.actions.previous') }}
          </div>
          <div class="wizard-action-button next" @click="handleSaveChanges">
            {{ getNextButtonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CustomIcon from "@/components/customIcon/customIcon.vue";
import {checkUserPermissions} from "@/assets/js/functions";

export default {
  name: "customWizard",
  components: {CustomIcon},
  props: {
    steps: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    getAvailableSteps () {
      let steps = []
      this.steps.forEach(item => {
        if (item.hasOwnProperty('permission') ? checkUserPermissions(item.permission) : true) {
          steps.push(item)
        }
      })
      return steps
    },
    getNextButtonText () {
      return this.activeIndex === this.getAvailableSteps.length - 1 ? this.$t('wizard.actions.confirm') : this.$t('wizard.actions.next')
    }
  },
  mounted() {
    if (this.getAvailableSteps.length > 0) {
      this.$emit('changeStep', this.getAvailableSteps[0].key)
    }
  },
  methods: {
    handleClickPreviousItem () {
      if (this.activeIndex > 0) {
        this.activeIndex--
        this.$emit('changeStep', this.getAvailableSteps[this.activeIndex].key)
      }
    },
    handleClickNextItem () {
      if (this.activeIndex < this.getAvailableSteps.length - 1) {
        setTimeout(() => {
          this.activeIndex++
          this.$emit('changeStep', this.getAvailableSteps[this.activeIndex].key)
        }, 10)
      }
    },
    handleSaveChanges () {
      const selectedOption = this.getAvailableSteps[this.activeIndex]
      this.handleClick(selectedOption.action)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuexy/variables";

.custom-wizard {
  height: 100%;

  .wizard-box {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 100%;

    .wizard-steps {
      padding: 10px;
      min-width: 300px;
      flex: 1;
      background-color: #f8f8f8;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: block;
        background: rgba(206, 206, 206, 0.50);
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        background: rgba(206, 206, 206, 1);
        border-radius: .5rem;
      }

      .wizard-step-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        & + .wizard-step-item .wizard-step-count::before {
          content: ' ';
          height: 31px;
          width: 2px;
          position: absolute;
          bottom: 100%;
          background-color: #cdcdcd;
        }

        &.passed {
          .wizard-step-count {
            background-color: $success;
            border-color: $success;

            &::before {
              background-color: $success;
            }
          }
        }

        &.active {
          .wizard-step-count {
            border-color: $primary;

            &::before {
              background-color: $success;
            }
          }
        }

        .wizard-step-count {
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #cdcdcd;
          border-radius: 50%;
          position: relative;
        }

        .wizard-step-text {
          margin-left: 10px;
        }

        .wizard-step-icon {
          margin-left: 15px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .wizard-content {
      flex: 3;
      min-width: 300px;
      padding: 10px;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: block;
        background: rgba(206, 206, 206, 0.50);
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        background: rgba(206, 206, 206, 1);
        border-radius: .5rem;
      }

      .wizard-actions {
        display: flex;
        justify-content: space-between;

        .wizard-action-button {
          cursor: pointer;
          padding: 8px 20px;
          margin-top: 10px;
          border-radius: 0.5rem;

          &.next {
            color: #fff;
            background-color: $success;
            border: 1px solid $success;
          }

          &.previous {
            border: 1px solid #cdcdcd;
          }
        }
      }
    }

    &.is-mobile {
      .wizard-steps {
        height: unset;
        width: 100%;
      }

      .wizard-content {
        height: unset;
        width: 100%;
      }
    }
  }
}
</style>