<template>
  <div class="club-wizard vx-card main-box top-zero-radius">
    <custom-wizard
        ref="clubWizard"
        :steps="options"
        @changeStep="activeStepKey = $event"
    >
      <club-info-setting
          v-if="activeStepKey === 'editClubInfo'"
          :update-navbar="false"
          @edited="$refs.clubWizard.handleClickNextItem()"
      />
      <insert-time
          v-if="activeStepKey === 'insertTime'"
          class="mt-2"
          @edit="generateTime(false)"
      />
    </custom-wizard>

    <custom-dialog
        ref="forceGenerateTimeConfirmation"
        :title="$t('times.confirmations.edit.title')"
        :body="$t('times.confirmations.edit.body')"
        @accept="generateTime(true)"
    />
  </div>
</template>

<script>
import axios from "axios";
import ClubInfoSetting from "@/views/admin/settings/clubInfo/clubInfoSetting.vue";
import CustomIcon from "@/components/customIcon/customIcon.vue";
import InsertTime from "@/views/admin/club/times/insert/insertTime.vue";
import CustomDialog from "@/components/customDialog/customDialog.vue";
import CustomWizard from "@/components/customWizard/customWizard.vue";
import {generateTime} from "@/http/requests/club/times";
import {checkUserPermissions} from "@/assets/js/functions";

export default {
  name: "clubWizard",
  components: {
    CustomWizard,
    CustomDialog,
    InsertTime,
    CustomIcon,
    ClubInfoSetting
  },
  data () {
    return {
      options: [
        {
          key: 'editClubInfo',
          text: this.$t('setting.clubInfo.title'),
          action: 'saveCompanyInfoSettingBTN',
          permission: 'setting.club.update'
        },
        {
          key: 'insertTime',
          text: this.$t('times.insert.title'),
          action: 'editTimeSettingBTN',
          permission: 'time.generate'
        }
      ],
      activeStepKey: ''
    }
  },
  methods: {
    generateTime(force = false) {
      if (checkUserPermissions(['time.generate', 'setting.club.update']) === 2) {
        generateTime(force).then((response) => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('times.notifications.insert.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
          this.$router.push({name: 'home'})
        })
            .catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                switch (error.response.status) {
                  case 406:
                    this.$refs.forceGenerateTimeConfirmation.showDialog()
                    break

                  default:
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('times.notifications.insert.error'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    break
                }
              }
            })
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('times.notifications.notAllowed'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss" scoped>
.club-wizard {
}
</style>